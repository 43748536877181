
// Standard Form jQuery ajax submit.

var irisStandardForm = $('#irisStandardForm');
irisStandardForm.submit(function (e) {

    // Disable and add a spinner to the submit button.
    $("#submit").addClass("disabled").html("<i class=\"fa fa-spinner fa-spin\"></i> Save");

    // Hide all previous errors.
    $('.alert-danger').hide();

    var restful_url = $("[name='restful_url']").val() ? $("[name='restful_url']").val() : false;
    $("[name='restful_url']").remove();

    // Prevent the form from being submitted.
    e.preventDefault();

    $.ajax({
        async: true,
        type: $("[name='_method']").val() ? $("[name='_method']").val() : irisStandardForm.attr('method'),
        url: irisStandardForm.attr('action'),
        data: irisStandardForm.serialize(),
        success: function (data) {

            $("#submit").removeClass("disabled").html("Save");

            switch(data.status) {
                case 'success':
                    $( "<div class=\"alert alert-success\"><strong>Success!</strong> "+ new Date().toLocaleString() +"</div>" ).insertBefore("#irisStandardForm");
                    $.each(data.fields, function( htmlId, value ) {
                        $("#"+htmlId).val(value);
                    });
                    if(data.redirect != null){
                        window.location.replace(data.redirect);
                    }
                    if(data.entity_id != null && restful_url != false){
                        // If the server returned the entities id, redirect to the edit page.
                        window.location.replace(restful_url+'/'+data.entity_id+'/edit');
                    }
                    break;
                case 'validation-error':
                    $.each(data.fields, function( htmlId, value ) {
                        $( "<div class=\"alert alert-danger\">"+value+"</div>" ).insertBefore("#"+htmlId);
                    });
                    break;
                case 'error':
                    $.each(data.fields, function( htmlId, value ) {
                        $( "<div class=\"alert alert-danger\">"+value+"</div>" ).insertBefore("#irisStandardForm");
                    });
                    break;
                default:
                    $( "<div class=\"alert alert-danger\"><strong>Systems error!</strong> Please try again.</div>" ).insertBefore("#irisStandardForm");
            }
        },
        error: function (data) {
            $( "<div class=\"alert alert-danger\"><strong>Systems error!</strong> Please try again.</div>" ).insertBefore("#irisStandardForm");
            $("#submit").removeClass("disabled").html("Save");
        },
    });

});
