
// irisDumpJsonForm jQuery ajax submit.

var irisDumpJsonForm = $('#irisDumpJsonForm');
irisDumpJsonForm.submit(function (e) {

    // Disable and add a spinner to the submit button.
    $("#submit").addClass("disabled").html("<i class=\"fa fa-spinner fa-spin\"></i> Save");

    // Hide all previous errors.
    $('.alert-danger').hide();

    var restful_url = $("[name='restful_url']").val() ? $("[name='restful_url']").val() : false;
    $("[name='restful_url']").remove();

    // Prevent the form from being submitted.
    e.preventDefault();

    $.ajax({
        async: true,
        type: $("[name='_method']").val() ? $("[name='_method']").val() : irisDumpJsonForm.attr('method'),
        url: irisDumpJsonForm.attr('action'),
        data: irisDumpJsonForm.serialize(),
        success: function (data) {

            $("#submit").removeClass("disabled").html("Submit");

            switch(data.status) {
                case 'success':
                    $( "<pre>"+ JSON.stringify(data, undefined, 2) +"</pre>" ).insertBefore("#irisDumpJsonForm");
                    break;
                case 'validation-error':
                    $.each(data.fields, function( htmlId, value ) {
                        $( "<div class=\"alert alert-danger\">"+value+"</div>" ).insertBefore("#"+htmlId);
                    });
                    break;
                case 'error':
                    $.each(data.fields, function( htmlId, value ) {
                        $( "<div class=\"alert alert-danger\">"+value+"</div>" ).insertBefore("#irisDumpJsonForm");
                    });
                    break;
                default:
                    $( "<div class=\"alert alert-danger\"><strong>Systems error!</strong> Please try again.</div>" ).insertBefore("#irisDumpJsonForm");
            }
        },
        error: function (data) {
            $( "<div class=\"alert alert-danger\"><strong>Systems error!</strong> Please try again.</div>" ).insertBefore("#irisDumpJsonForm");
            $("#submit").removeClass("disabled").html("Submit");
        },
    });

});
