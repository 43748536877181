
/*
| When #irisStandardDelete is clicked:
|
| 1) Show Modal - #irisStandardDeleteModal.
| 2) Get the route used for the HTTP DELETE request via data-delete-route.
| 3) Get the name of the item being deleted via data-to-delete.
*/
$(document).ready(function(){

    $(document).on("click", ".irisStandardDelete", function () {

        $('#irisStandardDeleteModal').modal('show');

        var htmlMessage = '<button id="confirmDelete" type="button" class="btn btn-danger">Yes</button>';
        $('#irisStandardDeleteModal #confirmDelete').replaceWith(htmlMessage);

        // Get the data passed to the modal.
        var data = $(this).data('json');

        // Error.
        if(!data.delete_route){
            alert('Error! Delete route missing.');
            $('#irisStandardDeleteModal').modal('hide');
        }

        // Unstage any previously selected rows.
        $('#irisStandardDatatable tr').each(function( index ) {
            $( this ).removeClass('stagedToDelete');
        });

        // Stage the currently selected row for delete.
        $(this).parents('tr').addClass('stagedToDelete');

        // Add data to the modal's data-json attribute.
        $('#irisStandardDeleteModal #confirmDelete').data('json', data);

        // Add the "name" to the model's html.
        // Example: "Are you sure you want to delete: NAME OF THING BEING DELETED?"
        $('#irisStandardDeleteModal #toDelete').html(data.to_delete);
    });

    $(document).on("click", "#irisStandardDeleteModal #confirmDelete", function () {

        // Get the data that was passed to the modal.
        var data = $(this).data('json');

        if(!data.delete_route){
            alert('Error! Delete route missing.');
            $('#irisStandardDeleteModal').modal('hide');
        }

        // Fire ajax request to delete the entity.
        $.ajax({
            async: true,
            url: data.delete_route,
            type: 'DELETE',
            success: function(result) {

                // Show a success message.
                var htmlMessage = '<div id="confirmDelete" class="alert alert-success"><strong>Success!</strong> Deleted: '+data.to_delete+'</div>';
                $('#irisStandardDeleteModal #confirmDelete').replaceWith(htmlMessage);

                // Remove the row via Datatables.
                var table = $('#irisStandardDatatable').DataTable();
                var rows = table.rows('.stagedToDelete')
                                .remove()
                                .draw();

                // Hide the modal in 3 seconds.
                setTimeout(function(){
                    $('#irisStandardDeleteModal').modal('hide');
                }, 3000);

            },
            error: function (request, status, error) {

                // Show an error message.
                var htmlMessage = '<div class="alert alert-danger"><strong>Error</strong> Unable to delete: '+data.to_delete+'</div>';
                $('#irisStandardDeleteModal #confirmDelete').replaceWith(htmlMessage);

                // Hide the modal in 3 seconds.
                setTimeout(function(){
                    $('#irisStandardDeleteModal').modal('hide');
                }, 3000);
                console.log(request.responseText);
            }
        });
    });

});
